<template>
	<div
		class="container"
		:class="{
			'no-heading-image': !page.headingImage,
			subpage: page.slug !== 'index',
			groups: page.slug == 'groups',
			vacancies: page.slug == 'vacancies-index',
			contact: page.slug == 'contact',
		}"
	>
		<Html :lang="locale" />
		<Head v-if="page">
			<Title>{{ page.metaTitle }}</Title>
			<Meta name="description" :content="page.metaDescription" />
			<Meta name="robots" :content="page.metaRobots" />
			<Hreflangs v-if="page.slug !== '404'" :langmenu="page.langmenu" />

			<client-only>
				<component
					:is="'script'"
					defer
					data-assistant="6711283298640491c8449aba"
					src="https://hiring-assistant.soigne.app/sdk.js"
				/>
			</client-only>
		</Head>

		<navigation />

		<client-only>
			<component :is="'script'">
				(function (d, s, id, h) { var ftjs = d.getElementsByTagName(s)[0]; if (d.getElementById(id)) return; var
				js = d.createElement(s); js.id = id; js.src = "https://cdn.formitable.com/sdk/v1/ft.sdk.min.js"; h &&
				(js.onload = h); ftjs.parentNode.insertBefore(js, ftjs); }(document, 'script', 'formitable-sdk',
				function () { FT.load('Analytics'); }));
			</component>
			<!-- Add gtag alias for Formitable -->
			<component :is="'script'">
				window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag('js', new
				Date()); gtag('config', 'G-69X99V57Z9');
			</component>
			<div
				class="ft-widget-b2"
				data-restaurant="42488b2f"
				data-open="1500"
				data-open-mobile="false"
				data-color="hsl(351, 18%, 21%)"
				:data-language="locale"
				data-tag="Website"
				data-toolbar="true"
				data-toolbar-mobile="true"
			></div>
		</client-only>

		<slot />

		<footers />

		<cookie-consent
			v-if="page && defaults[locale].cookieConsent.content"
			:content="defaults[locale].cookieConsent.content"
			:position="defaults[locale].cookieConsent.position"
		/>
	</div>
</template>

<script setup>
const { page, fetchPage } = usePage();
const { locale } = useI18n();
const config = useRuntimeConfig();
const route = useRoute();
const { defaults, fetchDefaults } = useDefaults();

if (!defaults.value) {
	await fetchDefaults();
}

await fetchPage();

const handleScroll = () => {
	const fixedElements = document.querySelectorAll('.fix-on-scroll');
	if (window.scrollY >= 650) {
		fixedElements.forEach((el) => el.classList.add('fixed'));
	} else {
		fixedElements.forEach((el) => el.classList.remove('fixed'));
	}
};

onBeforeMount(() => {
	window.addEventListener('scroll', handleScroll);
});

onBeforeUnmount(() => {
	window.removeEventListener('scroll', handleScroll);
});

const currentUrl = config.public.siteUrl + route.fullPath;
const { website } = defaults.value[locale.value];
const { metaDescription, metaTitle } = page.value;

useServerSeoMeta({
	twitterCard: metaDescription || '',
	twitterSite: currentUrl || '',
	twitterTitle: metaTitle || '',
	twitterDescription: metaDescription || '',
	twitterCreator: website.restaurantName || '',
	twitterImage: website.structuredImage || '',
	ogTitle: metaTitle || '',
	ogUrl: currentUrl || '',
	ogImage: website.structuredImage || '',
	ogDescription: metaDescription || '',
	ogSiteName: website.restaurantName || '',
});

useHead({
	script: [
		{
			type: 'application/ld+json',
			children: JSON.stringify({
				'@context': 'http://schema.org',
				'@graph': [
					{
						'@type': 'Restaurant',
						'@id': `${config.public.siteUrl}#restaurant`,
						image: website.structuredImage || '',
						url: config.public.siteUrl,
						name: website.restaurantName || '',
						logo: `${config.public.siteUrl}/images/logo-leo-goudvisch-full.png`,
						telephone: website.phone || '',
						email: website.email || '',
						address: {
							'@type': 'PostalAddress',
							streetAddress: `${website.street} ${website.number}, ${website.city}`,
							postalCode: website.postalCode,
						},
					},
				],
			}),
		},
	],
});
</script>
